@use "../global/" as g;

.p-top{
  width: 91.4%;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 120px;
  @include g.mq{
    width: 95.3%;
  }
  @include g.mq_m{
    padding-bottom: 256px;
  }
  &__title{
    margin-bottom: 40px;
    font-weight: bold;
    p{
      &:nth-child(1){
        font-family: 'DIN Alternate', sans-serif;
        margin-bottom: 12px;
        font-size: 18px;
        color: g.$c_blue;
        display: inline-block;
        padding-bottom: 4px;
        border-bottom: solid 2px g.$c_blue;
      }
      &:nth-child(2){
        color: g.$c_black;
        font-size: 32px;
        line-height: 1.4;
        @include g.mq{
          font-size: 42px;
        }
      }
      &:nth-child(3){
        color: g.$c_black;
        font-size: 16px;
        line-height: 1.2;
        margin-top: 16px;
        font-weight: 400;
      }
    }
  }
  &__service{
    margin-bottom: 80px;
    &__content{
      @include g.mq_mm{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }
    &__detail{
      padding: 32px 16px;
      border: solid 2px #F4F4F4;
      @include g.mq{
        padding: 32px;
      }
      @include g.mq_mm{
        width: 49%;
        display: flex;
        flex-direction: column;
        padding: 40px 32px;
      }
      &:not(:last-child){
        margin-bottom: 24px;
        @include g.mq_mm{
          margin-bottom: 0;
        }
      }
      &-title{
        font-size: 28px;
        line-height: 1.2;
        font-weight: bold;
        margin-bottom: 24px;
        .is-l_blue & {
          color: g.$c_blue;
        }
        .is-blue & {
          color: #0F98E1;
        }
      }
      &-text{
        margin-bottom: 16px;
        color: g.$c_black;
        line-height: 1.8;
      }
      &-btn{
        margin-bottom: 40px;
        // @include g.mq_mm{
        //   margin-top: auto;
        // }
        a{
          display: flex;
          width: 141px;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 4px;
          font-weight: 500;
          .is-l_blue & {
            color: g.$c_blue;
            &:hover{
              position: relative;
              @include g.mq_m(){
                cursor: pointer;
                &:before{
                  content: '';
                  position: absolute;
                  left: 0;
                  bottom: -5px;
                  border-bottom: solid 2px g.$c_blue;
                  animation: border_anim 0.3s linear forwards;
                }
              }
            }
          }
          .is-blue & {
            color: #0F98E1;
            &:hover{
              position: relative;
              @include g.mq_m(){
                cursor: pointer;
                &:before{
                  content: '';
                  position: absolute;
                  left: 0;
                  bottom: -5px;
                  border-bottom: solid 2px #0F98E1;
                  animation: border_anim 0.3s linear forwards;
                }
              }
            }
          }
        }
      }
      &-list{
        @include g.mq{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 8px 0;
        }
        @include g.mq_mm{
          // margin-top: auto;
        }
        li{
          height: 90px;
          transition: 0.3s;
          &:hover{
            p{
              &:nth-child(2){
                color: g.$c_white;
                font-weight: bold;
              }
            }
          }
          @include g.mq{
            width: 49.2%;
          }
          a{
            padding: 8px 12px;
            display: flex;
            align-items: center;
          }
          .is-l_blue & {
            background-color: #ECFBFF;
            &:hover{
              background-color: g.$c_blue;
            }
          }
          .is-blue & {
            background-color: #ECF8FF;
            &:hover{
              background-color: g.$c_blue;
            }
          }
          &:not(:last-child){
            margin-bottom: 8px;
            @include g.mq{
              margin-bottom: 0;
            }
          }
          p{
            &:nth-child(1){
              width: 72px;
              margin-right: 16px;
              transition: 0.3s;
              img{
                transition: 0.3s;
                width: 100%;
              }
            }
            &:nth-child(2){
              flex: 1;
              color: g.$c_black;
              font-size: 16px;
              line-height: 1.4;
              transition: 0.3s;
              span{
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
  &__know_how{
    margin-bottom: 80px;
    @include g.mq{
      margin-bottom: 160px;
    }
    &__content{
      @include g.mq_m{
        display: flex;
        flex-wrap: wrap;
      }
      li{
        border-top: solid 2px #F4F4F4;
        border-right: solid 2px #F4F4F4;
        border-left: solid 2px #F4F4F4;
        padding: 16px;
        @include g.mq{
          padding: 32px 20px;
        }
        @include g.mq_m{
          width: 33.333333333333333%;
          border-top: solid 2px transparent;
          border-bottom: solid 2px #F4F4F4;
        }
        &:nth-child(1),&:nth-child(2),&:nth-child(3){
          @include g.mq_m{
            border-top: solid 2px #F4F4F4;
          }
        }
        &:not(:nth-child(3n)){
          @include g.mq_m{
            border-right: solid 2px transparent;
          }
        }
        &:last-child{
          border-bottom: solid 2px #F4F4F4;
          @include g.mq_m{
            border-right: solid 2px #F4F4F4;
          }
        }
        a{
          display: block;
          transition: 0.3s;
          background-color: g.$c_white;
          @include g.mq_m{
            display: flex;
            flex-direction: column;
            height: 100%;
          }
          &:hover{
            cursor: pointer;
            transform: scale(1.03);
          }
        }
        p{
          &:nth-child(1){
            text-align: center;
            margin-bottom: 16px;
          }
          &:nth-child(2){
            color: g.$c_black;
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 16px;
            span{
              color: g.$c_blue;
            }
          }
          &:nth-child(3){
            color: g.$c_black;
            font-size: 16px;
            line-height: 1.6;
            margin-bottom: 16px;
          }
          &:nth-child(4){
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 0 8px;
            font-size: 16px;
            font-weight: 500;
            color: g.$c_blue;
            @include g.mq_m{
              margin-top: auto;
            }
            span{
              &:nth-child(1){
                position: relative;
                &:hover{
                  @include g.mq_m(){
                    cursor: pointer;
                    &:before{
                      content: '';
                      position: absolute;
                      left: 0;
                      bottom: -5px;
                      border-bottom: solid 2px g.$c_blue;
                      animation: border_anim 0.3s linear forwards;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}