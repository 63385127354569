@use "../global/" as g;

.o-flex{
  margin: 0 auto;
  position: relative;
  @include g.mq_m{
    display: flex;
    justify-content: space-between;
  }
  &.is-mb{
    margin: 0 auto 80px;
    @include g.mq_m{
      margin: 0 auto 128px;
    }
  }
  &__main{
    margin-bottom: 64px;
    @include g.mq_m{
      margin-bottom: 0;
      width: calc(100% - 360px);
    }
    .is-single & {
      position: relative;
    }
    &.is-w100{
      @include g.mq_m{
        width: 100%;
      }
    }
    &__content{
      margin-bottom: 64px;
      @include g.mq{
        margin-bottom: 80px;
      }
    }
  }
  &__sidebar{
    @include g.mq_m{
      width: 300px;
      padding-bottom: 0;
      padding-top: 80px;
    }
    &.is-single{
      @include g.mq_m{
        padding-top: 0;
      }
    }
  }
}