@use "../global/" as g;

.p-thanks{
  &__content{
    width: 100%;
    min-height: calc(100vh - 516px);
    &__text{
      margin-bottom: 40px;
      @include g.mq{
        margin-bottom: 64px;
      }
      p{
        &:not(:last-child){
          margin-bottom: 24px;
        }
      }
    }
    &__btn{
      @include g.mq_m{
        margin: 0 !important;
      }
    }
  }
}