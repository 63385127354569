@use "../global/" as g;

.o-form{
  background-color: g.$c_white;
  padding: 24px 16px;
  @include g.mq{
    padding: 40px 50px 64px;
  }
  &.is-contact{
    @include g.mq_m{
      padding: 60px 80px;
    }
  }
  &__row{
    border-bottom: dotted 1px #D9D9D9;
    padding: 36px 0;
    @include g.mq{
      padding: 36px 24px;
    }
    .is-contact & {
      @include g.mq{
        display: flex;
      }
      @include g.mq_m{
        padding: 20px 24px;
      }
    }
    &.is-last{
      margin-bottom: 16px;
      @include g.mq{
        margin-bottom: 24px;
      }
    }
    &__title{
      margin-bottom: 16px;
      font-size: 18px;
      display: flex;
      .is-contact & {
        @include g.mq{
          margin-bottom: 0;
          width: 206px;
          padding-top: 22px;
          position: relative;
        }
      }
      p{
        display: flex;
        align-items: center;
      }
      span{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 20px;
        font-size: 12px;
        font-weight: 500;
        color: g.$c_white;
        width: 32px;
        height: 17px;
        background-color: #FF8E3F;
        border-radius: 1px;
        .is-contact & {
          @include g.mq{
            position: absolute;
            right: 17px;
            top: 22px;
          }
        }
      }
    }
    &__input{
      .is-contact & {
        @include g.mq{
          flex: 1;
        }
      }
      input{
        width: 100%;
        background-color: #F9F9F9;
        border: solid 1px #BCBCBC;
        font-size: 18px;
        font-weight: 500;
        padding: 0 16px;
        height: 60px;
        border-radius: 5px;
      }
      textarea{
        width: 100%;
        background-color: #F9F9F9;
        border: solid 1px #BCBCBC;
        font-size: 18px;
        font-weight: 500;
        padding: 16px;
        height: 160px;
        border-radius: 5px;
        resize: vertical;
      }
      .wpcf7-not-valid-tip{
        margin-top: 16px;
        display: inline-block;
        font-size: 16px;
      }
    }
  }
  &__privacy{
    margin-bottom: 24px;
    text-align: center;
    font-size: 14px;
    input[type="checkbox"]{
      width: 18px;
      height: 18px;
    }
    a{
      display: inline-block;
      margin-left: 8px;
      color: g.$c_black;
      text-decoration: underline;
    }
  }
  &__submit{
    width: 100%;
    max-width: 310px;
    height: 56px;
    margin: 0 auto;
    border-radius: 28px;
    overflow: hidden;
    position: relative;
    &:before{
      content: "";
      position: absolute;
      width: 8.17px;
      height: 7px;
      background-image: url(../images/common/white_yajirushi.svg);
      background-size: 100% 100%;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 24px;
    }
    @include g.mq{
      max-width: 264px;
    }
    p{
      height: 100%;
    }
    input{
      width: 100%;
      height: 100%;
      background-color: g.$c_blue;
      font-size: 14px;
      color: g.$c_white;
      &:disabled {
        background: #ccc;
      }
      &:hover{
        cursor: pointer;
      }
    }
  }
}