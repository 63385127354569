@use "../global/" as g;

.o-btn {
  &.is-fixed{
    background-color: transparent;
    width: 100%;
    height: 72px;
    position: fixed;
    bottom: -72px;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    &.UpMove{
      animation: UpAnime 0.5s forwards;
    }
    &.DownMove{
      animation: DownAnime 0.5s forwards;
    }
    @include g.mq{
      display: none !important;
    }
    a{
      width:50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: g.$c_white;
      font-weight: 500;
      &:nth-child(1){
        background-color: g.$c_blue;
      }
      &:nth-child(2){
        background-color: g.$c_orange;
      }
      p:nth-child(1){
        display: inline-block;
        margin-right: 8px;
      }
    }
  }
  &.is-blog{
    width: 100%;
    max-width: 224px;
    margin: 0 auto;
    background-color: g.$c_blue;
    border: solid 1px g.$c_blue;
    color: g.$c_white;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0px 0px 9px rgba(0, 0, 0, 0.1));
    border-radius: 68px;
    font-size: 16px;
    font-weight: bold;
    transition: 0.3s;
    padding: 24px 0;
    line-height: 1;
    overflow: hidden;
    @include g.mq{
      max-width: 288px;
    }
    &:hover{
      background-color: g.$c_white;
      color: g.$c_blue;
    }
  }
  &.is-single{
    background-color: #F3A740;
    width: 100%;
    max-width: 310px;
    margin: 0 auto 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 68px;
    color: g.$c_white;
    font-size: 16px;
    line-height: 1.6;
    height: 80px;
    transition: 0.3s;
    border: solid 1px #F3A740;
    font-weight: bold;
    position: relative;
    &:before{
      content: '';
      width: 14px;
      height: 14px;
      border-top: solid 2px g.$c_white;
      border-right: solid 2px g.$c_white;
      position: absolute;
      right: 24px;
      top: 0px;
      bottom: 0;
      margin: auto;
      transform: rotate(45deg);
      transition: 0.3s;
    }
    span{
      display: inline-block;
      transition: 0.3s;
      &.is-color{
        color: #FFF574;
        transition: 0.3s;
      }
    }
    &:hover{
      opacity: 1;
      background-color: g.$c_white;
      color: #F3A740;
      &:before{
        border-top: solid 2px #F3A740;
        border-right: solid 2px #F3A740;
      }
      span{
        &.is-color{
          color: #F3A740;
        }
      }
    }
  }
}
