@use "../global/" as g;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;800;900&family=Zen+Kaku+Gothic+New:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.cdnfonts.com/css/din-alternate');


html, body {
  font-family: 'Noto Sans JP', sans-serif;
  overflow-x: clip;
}

.font_noto{
  font-family: 'Noto Sans JP', sans-serif;
}

.font_en{
  font-family: 'DIN Alternate', sans-serif;
}

.font_kozuka{
  font-family: "Kozuka Gothic Pr6N", sans-serif;
}

.font_yugo{
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.font_yumin{
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
}

.font_hirasan{
  font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "ヒラギノ角ゴシック", Meiryo, メイリオ, sans-serif;
}

.font_hiramin {
  font-family: "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
}

.font_hirakaku {
  font-family: "HiraKakuProN-W3","ヒラギノ角ゴ ProN W3","ヒラギノ角ゴ Pro W3","HiraKakuPro-W3","HiraKakuProN-W6", "ヒラギノ角ゴ ProN W6","メイリオ",Meiryo,"ＭＳ Ｐゴシック","MS Pgothic","Osaka",sans-serif,Helvetica, Helvetica Neue, Arial, Verdana;
}

.font_hel{
  font-family: Helvetica, Helvetica Neue,sans-serif,;
}

.font_meirio{
  font-family:'メイリオ', 'Meiryo','ＭＳ ゴシック','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3',sans-serif;
}


h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
}

header, nav, main, footer, small, button, label {
  display: block;
}

a,button {
  cursor: pointer;
  text-decoration: none;
  max-width: 100%;
}

button:hover, button:active{
  outline: 0;
  opacity: 0.9;
  text-decoration: none;
}

input, button, textarea, select {
  margin: 0;
  padding: 0;
  border-radius: 0;
  outline: none;
}

a:hover, a:active{
  outline: 0;
  opacity: 0.9;
  text-decoration: none;
}

::-webkit-input-placeholder {
  color: #a5a5a5;
}
:-ms-input-placeholder {
  color: #a5a5a5;
}
::-moz-placeholder {
  color: #a5a5a5;
}
::-placeholder {
  color: #a5a5a5;
}

input, button, textarea, select {
  margin: 0;
  padding: 0;
  background: #f2f2f2;
  border: 1px solid #b5b5b5;
  border-radius: 0;
  outline: none;
}
input[type="checkbox"] {
  cursor: pointer;
}
select {
  cursor: pointer;
}

.wrapper {
  width: 100%;
  overflow: hidden;
}

.containner {
  padding-bottom: 40px;
  @include g.mq {
    padding-bottom: 80px;
  }
  @include g.m(pb_none) {
    padding-bottom: 0;
  }
}

.flex{
  display: flex;
}


.innerBox {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 16px;
  position: relative;
  @include g.mq{
    padding: 0 24px;
  }
  @include g.mq_mm{
    padding: 0;
  }
  @include g.mq_ll{
    max-width: 1376px;
  }
  &.is-h100{
    height: 100%;
  }
}

.min-620{
  min-height: 620px;
}

.mb-30{
  margin-bottom: 30px;
}

.bold {
  font-weight: bold;
}

.medium {
  font-weight: 500;
}

.left{
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.display_to_mq {
  @include g.mq {
    display: none;
  }
}

.display_to_mq_m {
  @include g.mq_m {
    display: none;
  }
}

.display_mq {
  display: none;
  &.is-inline{
    @include g.mq {
      display: inline;
    }
  }
  &.is-block{
    @include g.mq {
      display: block;
    }
  }
}

.display_mq_m {
  display: none;
  &.is-inline{
    @include g.mq_m {
      display: inline;
    }
  }
  &.is-block{
    @include g.mq_m {
      display: block;
    }
  }
}



.relative_wrap {
  position: relative;
}


.inline_block {
  display: inline-block;
}


.text_indent {
  padding-left: 1em;
  text-indent: -1em;
}



.font_mincho {
  font-family: "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", "Yu Mincho", "YuMincho", serif;
}

.font_gothic {
  font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, arial, helvetica, sans-serif;
}

.c_pink {
  color: #f29c9f;
}

.post_cnt {
  iframe {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }
}

img.aligncenter {
  display: block;
  margin: 0 auto;
}

img.alignleft {
  display: block;
  margin-right: auto;
}

img.alignright {
  display: block;
  margin-left: auto;
}

input[type="submit"],
input[type="button"] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
    &::-webkit-search-decoration {
      display: none;
    }
    &::focus {
      outline-offset: -2px;
    }
}
main{
  min-height: calc(100vh - 144px);
  position: relative;
  z-index: 1;
  @include g.mq_m(){
    min-height: calc(100vh - 158px);
  }
  @include g.mq_mm{
    min-height: calc(100vh - 515px);
  }
}

.is-margin_header{
  margin-top: 65px;
  @include g.mq_design(){
    margin-top: 155px;
  }
}

#page__top{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #1A1A1A;
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 10;
  @include g.mq_mm(){
    right: 20px;
    bottom: 20px;
  }
  &:before{
    content: '';
    width: 10px;
    height: 10px;
    border-top: solid 2px g.$c_white;
    border-right: solid 2px g.$c_white;
    position: absolute;
    left: 0px;
    top: 5px;
    bottom: 0;
    right: 0;
    margin: auto;
    transform: rotate(-45deg);
  }
}


a[href^="tel:"] {
  @include g.mq{
    pointer-events: none;
  }
}

.is-fixed{
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.is-image{
  position: relative;
  .hover{
    overflow:hidden;
    width: 100%;
    height: 100%;
    padding-top: 52.2%;
    position: relative;
    border-radius: 5px;
    a:hover{
      opacity: 1;
    }
    img{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      object-fit: cover;
      transition:1s all;
      @include g.mq_m{
        &:hover{
          transform:scale(1.1,1.1);
          transition:1s all;
        }
      }
    }
  }
}

.scroll{
  position: fixed;
  width: 45px;
  height: 45px;
  right: 10px;
  bottom: 184px;
  z-index: 9999;
  @include g.mq{
    right: 2%;
    bottom: 8%;
    &:hover{
      cursor: pointer;
      opacity: 1;
    }
  }
}

.hover_btn{
  position: relative;
  a{
    overflow: hidden;
    display: inline-block;
    position: relative;
    &:hover{
      &:before{
        animation: hover_circle .75s;
      }
      &:before{
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        content: '';
        width: 0;
        height: 0;
        background: rgba(255,255,255,.7);
        border-radius: 100%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        opacity: 0;
      }
    }
  }
}

.blogcard{
  background-color: #F9F9F9;
  margin-bottom: 48px;
  padding: 20px 16px;
  a{
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 1.8;
    color: g.$c_black;
    font-weight: bold;
    @include g.mq{
      font-size: 16px;
    }
  }
  @include g.mq{
    margin-bottom: 40px;
    padding: 16px;
  }
  &_thumbnail{
    width: 108px;
    margin-right: 24px;
    @include g.mq{
      width: 181px;
    }
  }
  &_content{
    flex: 1;
  }
}