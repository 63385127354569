@use "../global/" as g;

.l-footer{
  width: 100%;
  background-color: g.$c_blue;
  position: relative;
  z-index: 1;
  padding: 64px 0 120px;
  margin-top: 96px;
  @include g.mq{
    margin-bottom: 0;
    padding: 72px 0 24px;
  }
  @include g.mq_m{
    height: 140px;
    padding: 0 0 24px;
    margin-top: 0;
    display: flex;
    align-items: flex-end;
  }
  &__illust{
    position: absolute;
    top: -156px;
    right: 0;
    left: 0;
    margin: auto;
    width: 100%;
    max-width: 343px;
    @include g.mq_m{
      max-width: 645px;
      left: initial;
      top: -232px;
    }
  }
  &__inner{
    width: 91.4%;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    @include g.mq{
      width: 95.3%;
    }
    @include g.mq_m{
      display: flex;
      align-items: center;
    }
  }
  &__logo{
    width: 100%;
    max-width: 200px;
    margin: 0 auto 32px;
    @include g.mq_m{
      margin: 0;
    }
  }
  &__copyright{
    text-align: center;
    font-size: 14px;
    color: g.$c_white;
    @include g.mq_m{
      margin: 0 0 0 auto;
    }
  }
  &__list{
    width: 100%;
    margin-bottom: 64px;
    @include g.mq{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 40px;
      gap: 0 24px;
    }
    @include g.mq_m{
      margin: 0 0 0 40px;
      width: auto;
    }
    li{
      text-align: center;
      &:not(:last-child){
        margin-bottom: 16px;
        @include g.mq{
          margin-bottom: 0;
          position: relative;
          &:before{
            content: "";
            position: absolute;
            width: 1px;
            height: 24px;
            background-color: g.$c_white;
            right: -14px;
            top: -2px;
          }
        }
      }
      a{
        color: g.$c_white;
        font-size: 16px;
        font-weight: 300;
        position: relative;
        @include g.mq{
          font-size: 14px;
        }
        &:hover{
          opacity: 1;
          cursor: pointer;
          @include g.mq_m(){
            cursor: pointer;
            &:before{
              content: '';
              position: absolute;
              left: 0;
              bottom: -5px;
              border-bottom: solid 1px g.$c_white;
              animation: border_anim 0.3s linear forwards;
            }
          }
        }
      }
    }
  }
}