@use "../global/" as g;

.o-contact_btn{
  width: 91.4%;
  height: 54px;
  max-width: 440px;
  background-color: g.$c_orange;
  border-radius: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  line-height: 1.6;
  color: g.$c_white;
  font-weight: bold;
  position: relative;
  border: solid 1px g.$c_orange;
  transition: 0.3s;
  margin: 0 auto 48px;
  @include g.mq{
    height: 80px;
    font-size: 20px;
  }
  &:hover{
    background-color: g.$c_white;
    color: g.$c_orange;
    opacity: 1;
    &:before{
      border-top: solid 2px g.$c_orange;
      border-right: solid 2px g.$c_orange;
    }
  }
  &:before{
    content: "";
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    border-top: solid 2px g.$c_white;
    border-right: solid 2px g.$c_white;
    position: absolute;
    right: 24px;
    top: 0px;
    bottom: 0;
    margin: auto;
    transition: 0.3s;
    transform: rotate(45deg);
  }
}