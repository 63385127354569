@use "../global/" as g;

.l-header{
  width: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  height: 67px;
  background-color: g.$c_white;
  transition: 0.4s;
  @include g.mq_m{
    height: 148px;
  }
  &.is-open{
    height: 100vh;
    height: -webkit-fill-available;
    .innerBox{
      align-items: flex-start;
    }
  }
  &__btn{
    display: none;
    @include g.mq_m{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 513px;
      margin-right: 40px;
    }
    &__contact{
      width: 250px;
      height: 64px;
      background-color: g.$c_orange;
      border-radius: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: g.$c_white;
      font-weight: bold;
      position: relative;
      border: solid 1px g.$c_orange;
      transition: 0.3s;
      &:hover{
        background-color: g.$c_white;
        color: g.$c_orange;
        opacity: 1;
        &:before{
          border-top: solid 2px g.$c_orange;
          border-right: solid 2px g.$c_orange;
        }
      }
      &:before{
        content: "";
        position: absolute;
        content: '';
        width: 10px;
        height: 10px;
        border-top: solid 2px g.$c_white;
        border-right: solid 2px g.$c_white;
        position: absolute;
        right: 24px;
        top: 0px;
        bottom: 0;
        margin: auto;
        transition: 0.3s;
        transform: rotate(45deg);
      }
    }
  }
  &__content{
    height: 100%;
    margin: 0 auto;
    position: relative;
    .is-open & {
      background-color: g.$c_white;
    }
    @include g.mq_m{
      justify-content: space-between;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    @include g.mq_mm{
      padding: 0;
    }
  }
  &__logo{
    width: 160px;
    padding: 19px 0 0px 16px;
    @include g.mq_m{
      width: 256px;
      padding: 26px 0 33px 40px;
    }
    a{
      &:hover{
        opacity: 1;
        cursor: pointer;
      }
    }
  }
  &__list{
    display: none;
    height: calc(100% - 21px);
    background-color: g.$c_blue;
    padding: 24px 16px;
    overflow-y: auto;
    position: relative;
    margin-top: 21px;
    .is-open & {
      display: block;
    }
    @include g.mq_m{
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: auto;
      height: 48px;
      padding: 0;
      width: 100%;
      background-color: g.$c_blue;
      margin-top: 0;
    }
    .parent{
      margin-bottom: 40px;
      @include g.mq_m{
        margin-bottom: 0;
        display: flex;
        gap: 0 24px;
      }
      @include g.mq_m{
        gap: 0 40px;
      }
      li{
        position: relative;
        &.is-open{
          .child{
            visibility: visible;
            opacity: 1;
          }
        }
        &.is-sp{
          @include g.mq_m{
            display: none;
          }
        }
        a{
          font-size: 18px;
          font-weight: 500;
          color: g.$c_white;
          @include g.mq_m{
            font-size: 14px;
            display: inline-block;
            position: relative;
          }
          @include g.mq_mm{
            font-size: 18px;
          }
          &:hover{
            opacity: 1;
            cursor: pointer;
            @include g.mq_m(){
              cursor: pointer;
              &:before{
                content: '';
                position: absolute;
                left: 0;
                bottom: -15px;
                border-bottom: solid 4px g.$c_white;
                animation: border_anim 0.3s linear forwards;
              }
            }
          }
        }
        &:not(:last-child){
          margin-bottom: 32px;
          @include g.mq_m{
            margin-bottom: 0;
          }
        }
        .child{
          margin-top: 16px;
          display: flex;
          flex-wrap: wrap;
          transition: 0.3s;
          @include g.mq_m{
            visibility: hidden;
            gap: 10px 0;
            opacity: 0;
            margin-top: 0;
            position: absolute;
            top: 40px;
            left: 0;
            width: 600px;
            background-color: g.$c_white;
            box-shadow: 0 3px 6px rgb(0 0 0 /0.25);
            padding: 16px;
          }
          li{
            width: 30%;
            margin-bottom: 10px;
            @include g.mq_m{
              width: 19%;
              margin-bottom: 0;
            }
            &:not(:nth-child(3n)){
              margin-right: 5%;
              @include g.mq_m{
                margin-right: 0;
              }
            }
            &:not(:nth-child(5n)){
              @include g.mq_m{
                margin-right: 1%;
              }
            }
            a{
              color: g.$c_black;
              font-size: 14px;
              font-weight: 500;
              font-family: 'Noto Sans JP', sans-serif;
              padding: 8px 0 8px 20px;
              border: solid 1px #E0E0E0;
              display: block;
              border-radius: 4px;
              position: relative;
              @include g.mq_m{
                margin-right: 0;
                transition: 0.3s;
                text-align: center;
                padding: 8px 0;
              }
              &:hover{
                color: g.$c_white;
                border: solid 1px g.$c_blue;
                background-color: g.$c_blue;
              }
              &:before{
                content: "";
                position: absolute;
                width: 5px;
                height: 5px;
                background-color: g.$c_blue;
                border-radius: 1px;
                top: 0;
                bottom: 0;
                margin: auto;
                left: 10px;
                @include g.mq_m{
                  content: none;
                }
              }
            }
          }
        }
      }
    }
    &__cta{
      background-color: g.$c_yellow;
      border-radius: 4px;
      width: 100%;
      max-width: 312px;
      margin: 0 auto 24px;
      padding: 16px 24px;
      @include g.mq_m{
        display: none;
      }
      &-detail{
        margin-bottom: 16px;
        p{
          font-size: 14px;
          font-weight: bold;
          text-align: center;
          margin-bottom: 10px;
        }
        a{
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          height: 56px;
          width: 100%;
          border-radius: 60px;
          color: g.$c_white;
          font-size: 14px;
          font-weight: bold;
          &:before{
            content: "";
            position: absolute;
            width: 15.5px;
            height: 8.17px;
            background-image: url(../images/common/white_yajirushi.svg);
            background-size: 100% 100%;
            right: 24px;
            top: 0;
            bottom: 0;
            margin: auto;
          }
          &.is-green{
            background-color: g.$c_blue;
          }
          &.is-dark{
            background-color: g.$c_blue;
          }
          span:nth-child(1){
            position: absolute;
            top: 0;
            bottom:0;
            margin: auto;
            display: flex;
            align-items: center;
            left: 24px;
          }
        }
      }
      &-tel{
        text-align: center;
      }
    }
    &__btn{
      width: 100%;
      max-width: 247px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      height: 44px;
      background-color: g.$c_yellow;
      color: #333;
      font-size: 14px;
      font-weight: bold;
      @include g.mq_m{
        display: none;
      }
      span:nth-child(1){
        display: inline-block;
        margin-right: 8px;
      }
    }
  }
  &__ham{
    width: 48px;
    height: 48px;
    position: absolute;
    top: 8px;
    right: 16px;
    border-radius: 50%;
    z-index: 3;
    @include g.mq_m{
      display: none;
    }
    span{
      width: 24px;
      height: 2px;
      background-color: g.$c_blue;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      transition-duration:0.8s;
      &:nth-child(1){
        top: 16px;
      }
      &:nth-child(2){
        bottom: 16px;
      }
    }
  }
}



.active_line1{
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition-duration:1s;
  top: 22px !important;
  border-radius: 3px;
}

.active_line2{
  opacity: 0;
}

.active_line3{
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  top: 14px !important;
  right: -1px;
  border-radius: 3px;
}