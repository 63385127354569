@use "../global/" as g;

.l-sidebar{
  width: 100%;
  height: 100%;
  &__banner{
    text-align: center;
    margin-bottom: 40px;
  }
  &__cta{
    text-align: center;
    position: relative;
    transition: 0.3s;
    @include g.mq_m{
      visibility: hidden;
      opacity: 0;
      position: fixed;
      bottom: 0;
      top: 0;
      margin: auto;
      right: 40px;
      width: 300px;
    }
    &.is-close{
      display: none !important;
    }
    &.is-show{
      @include g.mq_m{
        visibility: visible;
        opacity: 1;
      }
    }
    &__close{
      position: absolute;
      width: 32px;
      height: 32px;
      top: -16px;
      right: -16px;
      z-index: 1;
      &:hover{
        cursor: pointer;
      }
    }
  }
  &__fixed{
    @include g.mq_m{
      position: sticky;
      top: 156px;
      right: 0;
    }
  }
  &__search{
    margin-bottom: 40px;
    form{
      width: 100%;
      max-width: 300px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      position: relative;
      input[type="submit"]{
        width: 40px;
        height: 40px;
        position: absolute;
        background-image: url(../images/common/search_submit.webp);
        background-size: 100% 100%;
        right: 0;
        top: 0;
        &:hover{
          cursor: pointer;
        }
        .no-webp & {
          background-image: url(../images/common/search_submit.png);
        }
      }
      input[type="text"]{
        flex: 1;
        border: solid 1px #C9C3C3;
        height: 40px;
        background-color: g.$c_white;
        padding: 0 56px 0 16px;
      }
    }
  }
  &__title{
    margin-bottom: 24px;
    padding: 8px 0;
    background-color: g.$c_blue;
    color: g.$c_white;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  &__article{
    &__detail{
      display: flex;
      &:not(:last-child){
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: solid 2px #F4F4F4;
      }
      .image{
        width: 108px;
        margin-right: 16px;
      }
      .title{
        flex: 1;
        color: g.$c_black;
        font-size: 16px;
        line-height: 1.4;
        font-weight: bold;
        a{
          color: g.$c_black;
        }
      }
    }
  }
}