@use "../global/" as g;

.o-bread_list{
  display: flex;
  flex-wrap: wrap;
  gap: 16px 0;
  margin-bottom: 16px;
  margin-top: 67px;
  width: 91.4%;
  max-width: 1200px;
  margin: 67px auto 16px;
  padding-top: 16px;
  @include g.mq{
    width: 95.3%;
  }
  @include g.mq_m{
    margin: 148px auto 16px;
  }
  &.is-single{
    margin: 67px auto 80px;
    @include g.mq_m{
      margin: 148px auto 80px;
    }
  }
  li{
    font-size: 12px;
    position: relative;
    line-height: 1.2;
    color: #9D9D9D;
    @include g.mq_m{
      font-size: 14px;
    }
    &:not(:last-child){
      margin-right: 24px;
      &:before{
        content: '';
        width: 8px;
        height: 8px;
        border-top: solid 1px #9D9D9D;
        border-right: solid 1px #9D9D9D;
        position: absolute;
        right: -16px;
        top: 4px;
        transform: rotate(45deg);
      }
    }
    a{
      color: #9D9D9D;
      position: relative;
      @include g.mq_m{
        &:hover{
          &:before{
            content: '';
            position: absolute;
            left: 0;
            bottom: -3px;
            height: 1px;
            background-color: #9D9D9D;
            animation: border_anim 0.3s linear forwards;
          }
        }
      }
    }
  }
}