@use "../global/" as g;

.o-mv{
  &.is-top{
    width: 100%;
    max-width: 1200px;
    margin: 67px auto 80px;
    padding: 32px 0 124px;
    position: relative;
    @include g.mq_m{
      margin: 148px auto 80px;
      padding: 64px 0;
    }
    @include g.mq_mm{
      padding: 128px 0;
    }
    .text{
      position: relative;
      z-index: 1;
      &__title{
        margin-bottom: 28px;
        color: g.$c_black;
        font-size: 40px;
        letter-spacing: 4px;
        line-height: 1.5;
        @include g.mq{
          font-size: 48px;
        }
        span{
          display: inline-block;
          font-weight: bold;
          &.dot{
            position: relative;
            color: g.$c_blue;
            font-size: 56px;
            @include g.mq{
              font-size: 64px;
            }
            &:before{
              content: "";
              position: absolute;
              width: 12px;
              height: 12px;
              background-color: g.$c_blue;
              border-radius: 50%;
              left: 0;
              right: 0;
              margin: auto;
              top: 0px;
            }
          }
        }
      }
      &__list{
        margin-bottom: 32px;
        li{
          padding-left: 28px;
          line-height: 19.2px;
          font-size: 16px;
          color: g.$c_black;
          position: relative;
          font-weight: bold;
          @include g.mq{
            font-size: 18px;
            line-height: 21.6px;
          }
          span{
            color: g.$c_orange;
            &.f24{
              font-size: 21px;
              @include g.mq{
                font-size: 24px;
              }
            }
          }
          &:before{
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            background-image: url(../images/top/mv_check.svg);
            background-size: 100% 100%;
            left: 0;
            top: 2px;
          }
          &:not(:last-child){
            margin-bottom: 16px;
          }
        }
      }
      &__btn{
        width: 100%;
        height: 64px;
        max-width: 347px;
        background-color: g.$c_orange;
        border-radius: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: g.$c_white;
        font-weight: bold;
        position: relative;
        border: solid 1px g.$c_orange;
        transition: 0.3s;
        @include g.mq{
          height: 74px;
        }
        &:hover{
          background-color: g.$c_white;
          color: g.$c_orange;
          opacity: 1;
          &:before{
            border-top: solid 2px g.$c_orange;
            border-right: solid 2px g.$c_orange;
          }
        }
        &:before{
          content: "";
          position: absolute;
          content: '';
          width: 10px;
          height: 10px;
          border-top: solid 2px g.$c_white;
          border-right: solid 2px g.$c_white;
          position: absolute;
          right: 24px;
          top: 0px;
          bottom: 0;
          margin: auto;
          transition: 0.3s;
          transform: rotate(45deg);
        }
      }
    }
    .image{
      display: flex;
      justify-content: center;
      position: relative;
      top: -56px;
      margin-bottom: -56px;
      @include g.mq_m{
        position: absolute;
        right: -24px;
      }
      @include g.mq_mm{
        top: -185px;
        right: -100px;
      }
      @include g.mq_l{
        right: -132px;
      }
      @include g.mq_design{
        right: -168px;
      }
      &__content{
        min-width: 446px;
        height: 446px;
        background-color: #F3FCFF;
        border-radius: 50%;
        max-width: none;
        position: relative;
        @include g.mq_mm{
          min-width: 810px;
          height: 810px;
        }
      }
      &__main{
        position: absolute;
        bottom: 20px;
        right: 0;
        left: 0;
        margin: auto;
        width: 285px;
        opacity: 0;
        animation: fadeIn 0.6s linear forwards;
        animation-delay: 0.6s;
        @include g.mq_mm{
          width: 486px;
        }
      }
      &__01{
        position: absolute;
        bottom: 246px;
        width: 104px;
        left: 59px;
        opacity: 0;
        animation: fadeIn 0.6s linear forwards;
        animation-delay: 1.2s;
        @include g.mq_mm{
          left: 32px;
          width: 183px;
          bottom: 386px;
        }
      }
      &__02{
        position: absolute;
        bottom: 276px;
        width: 119px;
        left: 127px;
        opacity: 0;
        animation: fadeIn 0.6s linear forwards;
        animation-delay: 1.8s;
        @include g.mq_mm{
          left: 184px;
          width: 208px;
          bottom: 436px;
        }
      }
      &__03{
        position: absolute;
        bottom: 271px;
        width: 123px;
        right: 103px;
        opacity: 0;
        animation: fadeIn 0.6s linear forwards;
        animation-delay: 2.4s;
        @include g.mq_mm{
          right:212px;
          width: 218px;
          bottom: 431px;
        }
      }
      &__04{
        position: absolute;
        bottom: 246px;
        width: 104px;
        right: 39px;
        opacity: 0;
        animation: fadeIn 0.6s linear forwards;
        animation-delay: 3.0s;
        @include g.mq_mm{
          right: 72px;
          width: 183px;
          bottom: 372px;
        }
      }
    }
    .mv_scroll{
      position: absolute;
      width: 29px;
      height: 100px;
      right: 0;
      left: 0;
      margin: 0 auto;
      bottom: 0;
      &:before{
        content: "";
        position: absolute;
        width: 8px;
        height: 8px;
        background-color: g.$c_blue;
        border-radius: 50%;
        left: -3px;
        top: -4px;
        animation: mv_scroll 2.4s linear alternate infinite;
      }
    }
  }
  &.is-lower{
    background-color: #ECFBFF;
    padding: 48px 0;
    overflow: hidden;
    position: relative;
    @include g.mq{
      padding: 80px 0;
    }
    .inner{
      width: 91.4%;
      max-width: 1200px;
      margin: 0 auto;
      position: relative;
      z-index: 1;
      @include g.mq{
        width: 95.3%;
      }
      .title{
        color: g.$c_black;
        font-size: 32px;
        line-height: 1.6;
        font-weight: bold;
        @include g.mq{
          font-size: 40px;
        }
        span{
          color: g.$c_blue;
        }
      }
    }
    .image{
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      text-align: right;
    }
  }
}