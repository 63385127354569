@use "config" as config;
@use "sass:map";


 @mixin mq {
  @media (min-width: config.$breakpoint) {
    @content;
  }
}

@mixin mq_m {
  @media (min-width: config.$breakpoint_m) {
    @content;
  }
}

@mixin mq_mm {
  @media (min-width: config.$breakpoint_mm) {
    @content;
  }
}

@mixin mq_l {
  @media (min-width: config.$breakpoint_l) {
    @content;
  }
}

@mixin mq_design {
  @media (min-width: config.$breakpoint_design) {
    @content;
  }
}

@mixin mq_ll {
  @media (min-width: config.$breakpoint_ll) {
    @content;
  }
}

@mixin mq_max {
  @media (min-width: config.$breakpoint_max) {
    @content;
  }
}


/*
 * BEM関連
 */
@mixin e($names...) {
  @each $name in $names {
    &__#{$name}{
      @content;
    }
  }
}

@mixin m($names...) {
  @each $name in $names {
    &.is-#{$name}{
      @content;
    }
  }
}

/*
 * clearfix
 */
@mixin cf {
  &::after {
    clear: both;
    content: "";
    display: block;
  }
}