@use "../global/" as g;

.p-single{
  width: 91.4%;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 80px;
  @include g.mq{
    padding-bottom: 128px;
    width: 95.3%;
  }
  @include g.mq_m{
    padding-bottom: 256px;
  }
  &__link{
    margin-bottom: 32px;
    li{
      line-height: 1.2;
      padding-left: 16px;
      position: relative;
      &:before{
        content: '';
        width: 10px;
        height: 10px;
        border-top: solid 2px g.$c_blue;
        border-right: solid 2px g.$c_blue;
        position: absolute;
        left: 0px;
        top: 0px;
        bottom: 0;
        margin: auto;
        transform: rotate(-135deg);
      }
      &:not(:last-child){
        margin-bottom: 16px;
      }
      a{
        color: g.$c_blue;
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 0.8px;
        position: relative;
        &:hover{
          @include g.mq_m(){
            cursor: pointer;
            &:before{
              content: '';
              position: absolute;
              left: 0;
              bottom: -4px;
              border-bottom: solid 2px g.$c_blue;
              animation: border_anim 0.3s linear forwards;
            }
          }
        }
      }
    }
  }
  &__title{
    font-size: 24px;
    color: g.$c_black;
    line-height: 1.4;
    font-weight: bold;
    margin-bottom: 16px;
    @include g.mq{
      font-size: 32px;
    }
    @include g.mq_m{
      font-size: 40px;
    }
  }
  &__data{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 24px;
    .category{
      display: flex;
      align-items: center;
      gap: 0 8px;
      color: g.$c_blue;
      font-size: 14px;
    }
    .day{
      display: flex;
      align-items: center;
      gap: 0 12px;
      font-size: 12px;
      color: g.$c_black;
      .date{
        color: #9D9D9D;
        font-size: 14px;
        time{
          display: flex;
          align-items: center;
          gap: 0 8px;
        }
      }
    }
  }
  &__image{
    margin-bottom: 40px;
    text-align: center;
  }
  &__excerpt{
    margin-bottom: 48px;
    color: g.$c_black;
    font-size: 16px;
    line-height: 1.6;
    p{
      &:not(:last-child){
        margin-bottom: 24px;
      }
    }
  }
  &__list{
    width: 100%;
    padding: 64px 16px 24px;
    margin: 0 auto 24px;
    background-color: #F8F8F8;
    position: relative;
    border-radius: 4px;
    border-top: solid 8px g.$c_blue;
    @include g.mq{
      width: 100%;
      padding: 60px 32px 40px;
      margin: 0 auto 50px;
    }
    &__title{
      color: g.$c_black;
      font-size: 18px;
      font-weight: 500;
      position: absolute;
      display: flex;
      align-items: flex-end;
      top: 0;
      left: 0;
      right: 0;
      height: 63px;
      width: calc(100% - 32px);
      margin: auto;
      padding-bottom: 16px;
      @include g.mq{
        width: calc(100% - 64px);
        height: 73px;
      }
      .js-list_button{
        @include g.mq{
          &:hover{
            cursor: pointer;
          }
        }
      }
    }
    &__content{
      margin-top: 8px;
      margin-bottom: 10px;
      padding-left: 24px !important;
      @include g.mq{
        margin-top: 24px;
        margin-bottom: 20px;
      }
      &-h2{
        margin-bottom: 0 !important;
      }
      &-h3{
        padding-left: 1em;
        margin-bottom: 32px;
        margin-top: 8px;
        .p-single__list__item{
          border-bottom: 0px;
          &:not(:last-child){
            margin-bottom: 0;
          }
        }
      }
      &-h4{
        padding-left: 1em;
        margin-bottom: 0 !important;
        .p-single__list__item{
          margin-bottom: 0;
          &:not(:last-child){
            margin-bottom: 0;
          }
        }
      }
    }
    &__item{
      list-style: none !important;
      position: relative;
      &:before{
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
        border-top: 11px solid g.$c_blue;
        border-bottom: 0;
        left: -24px;
        top: 9px;
      }
      li{
        list-style: none !important;
        &:before{
          border-right: 7px solid transparent;
          border-left: 7px solid transparent;
          border-top: 10px solid g.$c_blue;
          top: 10px;
        }
      }
      a{
        font-size: 16px;
        color: g.$c_black;
        line-height: 1.4;
        letter-spacing: 0.8px;
        position: relative;
        font-weight: 500;
        strong{
          font-weight: 500;
        }
        &:hover{
          opacity: 1;
          @include g.mq(){
            cursor: pointer;
            &:before{
              content: '';
              position: absolute;
              left: 0;
              bottom: -1px;
              border-bottom: solid 1px #707070;
              animation: border_anim 0.3s linear forwards;
            }
          }
        }
      }
    }
  }
  &__content{
    padding-bottom: 80px;
    position: relative;
    background-color: g.$c_white;
    border-bottom: solid 2px #E6E6E6;
    margin-bottom: 40px;
    blockquote{
      color: g.$c_black;
      font-size: 15px;
      line-height: 1.8;
      background-color: #F9F9F9;
      padding: 24px;
      margin-bottom: 24px;
      @include g.mq{
        margin-bottom: 40px;
      }
      p{
        color: g.$c_black;
        font-size: 15px;
        line-height: 1.8;
        margin-bottom: 0;
        font-style: oblique;
      }
    }
    .table_wrap{
      overflow-x: auto !important;
      overflow-y: hidden !important;
      max-width: 100%;
      width: 100%;
      margin-bottom: 20px;
      @include g.mq{
        margin-bottom: 32px;
      }
      table{
        border: solid 1px #EEEEEE;
        width: auto !important;
        th{
          border: solid 1px #EEEEEE;
          padding: 16px;
          font-size: 16px;
          line-height: 1.5;
          color: g.$c_black;
          width: auto !important;
          background-color: #EEEEEE;
        }
        td{
          border: solid 1px #EEEEEE;
          padding: 16px;
          font-size: 16px;
          width: auto !important;
        }
      }
    }
    caption{
      margin-bottom: 24px;
    }
    h2,h3,h4,h5{
      position: relative;
      font-weight: bold;
    }
    h2{
      font-size: 18px;
      line-height: 1.4;
      color: g.$c_black;
      margin-bottom: 32px;
      padding: 16px 16px 16px 24px;
      border-left: solid 5px g.$c_blue;
      position: relative;
      letter-spacing: 0.9px;
      background-color: #F8F8F8;
      @include g.mq{
        font-size: 32px;
        letter-spacing: 1.6px;
      }

    }
    h3{
      color: g.$c_black;
      font-size: 18px;
      line-height: 1.4;
      margin-bottom: 32px;
      padding-left: 12px;
      position: relative;
      border-left: solid 3px g.$c_blue;
      @include g.mq{
        font-size: 24px;
        padding-left: 24px;
        border-left: solid 5px g.$c_blue;
      }
    }
    h4{
      font-size: 18px;
      line-height: 1.4;
      color: g.$c_black;
      margin-bottom: 32px;
      padding-bottom: 8px;
      border-bottom: solid 2px g.$c_blue;
      position: relative;
    }
    h5{
      color: g.$c_black;
      font-size: 18px;
      line-height: 1.8;
      margin-bottom: 32px;
    }
    p{
      font-size: 16px;
      margin-bottom: 32px;
      color: g.$c_black;
      line-height: 1.6;
      mark{
        font-style: inherit;
        color: g.$c_black;
      }
      &.is-mb_js{
        margin-bottom: 48px;
      }
    }
    table{
      margin-bottom: 24px;
      width: 100%;
      border: solid 1px #EEEEEE;
      th{
        border: solid 1px #EEEEEE;
        background-color: #EEEEEE;
        padding: 16px;
        font-size: 16px;
        line-height: 1.5;
        color: g.$c_black;
      }
      td{
        border: solid 1px g.$c_black;
        padding: 16px;
        font-size: 16px;
        line-height: 1.5;
      }
      @include g.mq{
        margin-bottom: 40px;
      }
    }
    ol{
      padding-left: 1em;
      margin-bottom: 24px;
      @include g.mq{
        margin-bottom: 40px;
      }
      li{
        font-size: 16px;
        line-height: 1.8;
        list-style-type: decimal;
        color: g.$c_black;
      }
    }
    ul{
      padding-left: 1em;
      margin-bottom: 24px;
      @include g.mq{
        margin-bottom: 40px;
      }
      li{
        font-size: 16px;
        line-height: 1.8;
        position: relative;
        &:before{
          content: "";
          position: absolute;
          width: 8px;
          height: 8px;
          border-radius: 1.2px;
          top: 12px;
          left: calc(2px - 1em);
          background-color: g.$c_blue;
        }
      }
    }
  }
  &__quote{
    border: solid 1px g.$c_blue;
    background-color: rgba(223,237,248,0.67);
    margin-bottom: 20px;
    padding: 12px 8px 24px;
    @include g.mq{
      margin-bottom: 60px;
      padding: 16px 12px 40px;
    }
    &__title{
      font-size: 10px;
      color: #707070;
      margin-bottom: 14px;
      padding-left: 35px;
      @include g.mq{
        font-size: 12px;
      }
      @include g.mq_m{
        font-size: 16px;
        padding-left: 70px;
      }
    }
    &__content{
      display: flex;
      align-items: center;
      &-image{
        width: 20px;
        margin-right: 15px;
        @include g.mq_m{
          width: 40px;
          margin-right: 30px;
        }
      }
      &-text{
        flex: 1;
        color: #333;
        line-height: 1.5;
        font-size: 10px;
        @include g.mq{
          font-size: 12px;
        }
        @include g.mq_m{
          font-size: 16px;
          line-height: 30px;
        }
      }
    }
  }
  &__supervision{
    margin-bottom: 56px;
    background-color: #F9F9F9;
    padding: 64px 24px 40px;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    @include g.mq{
      margin-bottom: 72px;
      padding: 64px 45px 40px;
    }
    &__title{
      background-color: #343434;
      width: 140px;
      height: 37px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0px;
      left: 0;
      border-radius: 0 0 4px 0;
      font-size: 14px;
      color: g.$c_white;
      font-weight: bold;
    }
    &__content{
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      &-image{
        width: 25%;
        max-width: 65px;
        margin-right: 24px;
      }
      &-text{
        flex: 1;
        .organization{
          font-size: 12px;
          color: g.$c_black;
          line-height: 1;
          margin-bottom: 16px;
        }
        .name{
          font-size: 20px;
          color: g.$c_black;
          font-weight: bold;
        }
      }
    }
    .description{
      color: g.$c_black;
      font-size: 16px;
      line-height: 1.8;
      margin-bottom: 16px;
      @include g.mq{
        margin-bottom: 50px;
      }
    }
    .btn{
      width: 100%;
      max-width: 320px;
      margin: 0 auto;
      @include g.mq{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        max-width: 654px;
      }
      .absolute_text{
        font-size: 13px;
        color: g.$c_black;
        text-align: center;
        margin-bottom: 8px;
        font-weight: 500;
        @include g.mq{
          position: absolute;
          width: 100%;
          max-width: 300px;
          left: 0;
          top: -20px;
        }
        @include g.mq_m{
          max-width: 270px;
        }
        @include g.mq_mm{
          max-width: 327px;
        }
      }
      a{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        height: 80px;
        &.is-red{
          margin: 0 auto 16px;
          background-color: #EE3E3E;
          border: solid 1px #EE3E3E;
          color: g.$c_white;
          position: relative;
          @include g.mq{
            width: 300px;
            margin: 0;
          }
          @include g.mq_m{
            width: 270px;
          }
          @include g.mq_mm{
            width: 327px;
          }
        }
        &.is-white{
          background-color: g.$c_white;
          border: solid 1px g.$c_black;
          color: g.$c_black;
          @include g.mq{
            width: 300px;
          }
          @include g.mq_m{
            width: 270px;
          }
          @include g.mq_mm{
            width: 312px;
          }
        }
        p:nth-child(1){
          display: flex;
          align-items: center;
          font-weight: bold;
          margin-bottom: 8px;
          span:nth-child(1){
            margin-right: 8px;
            display: inline-block;
          }
          font-size: 16px;
        }
        p:nth-child(2){
          font-size: 12px;
        }
      }
    }
  }
  &__article{
    margin-top: 56px;
    @include g.mq{
      display: flex;
      justify-content: space-between;
      margin-top: 72px;
    }
    &__detail{
      width: 100%;
      max-width: 332px;
      display: flex;
      &:not(:last-child){
        margin-bottom: 32px;
        @include g.mq{
          margin-bottom: 0;
        }
      }
      .image{
        width: 112px;
        margin-right: 16px;
      }
      .text{
        flex: 1;
        p:nth-child(1){
          color: g.$c_black;
          font-size: 14px;
          font-weight: bold;
          line-height: 1.8;
          a{
            color: g.$c_black;
          }
        }
        p:nth-child(2){
          font-size: 12px;
          color: g.$c_black;
          line-height: 1.7;
          font-weight: 400;
          a{
            color: g.$c_black;
          }
        }
      }
    }
  }
  &__writer{
    margin-bottom: 80px;
    @include g.mq{
      margin-bottom: 96px;
    }
    &__title{
      margin-bottom: 24px;
      color: g.$c_blue;
      font-weight: bold;
      font-size: 16px;
    }
    &__content{
      @include g.mq{
        display: flex;
      }
      &-image{
        width: 160px;
        margin: 0 auto 24px;
        @include g.mq{
          margin: 0 24px 0 0;
        }
      }
      &-text{
        @include g.mq{
          flex: 1;
        }
        p{
          &:nth-child(1){
            font-size: 24px;
            color: g.$c_black;
            font-weight: bold;
            margin-bottom: 16px;
            line-height: 1.2;
            padding-bottom: 8px;
            border-bottom: solid 1px #E6E6E6;
          }
          &:nth-child(2){
            color: g.$c_black;
            font-size: 16px;
            line-height: 1.6;
          }
        }
      }
    }
  }
  &__cta{
    margin-bottom: 40px;
  }
  &__other{
    &__title{
      font-size: 24px;
      color: g.$c_black;
      font-weight: bold;
      padding-bottom: 16px;
      margin-bottom: 24px;
      border-bottom: solid 2px #E6E6E6;
      @include g.mq{
        font-size: 32px;
      }
    }
  }
}