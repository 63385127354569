/*
 * 色の設定
 */

$c_white: #ffffff;
$c_yellow: #FAF7EF;
$c_black: #3C3C3C;
$c_orange: #F3A641;
$c_blue: #07A5CD;


//ブレイクポイント
$breakpoint: 768px;
$breakpoint_m: 1024px;
$breakpoint_mm: 1180px;
$breakpoint_l: 1280px;
$breakpoint_design: 1336px;
$breakpoint_ll: 1728px;
$breakpoint_max: 1920px;