@use "../global/" as g;

.o-pagenation{
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  .wp-pagenavi{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 32px;
    a{
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 44px;
      width: 48px;
      height: 48px;
      border-radius: 4px;
      font-size: 16px;
      font-weight: bold;
      color:g.$c_blue;
      margin: 0 4px;
      overflow: hidden;
      position: relative;
      transition: 0.1s;
      border: 0px;
      border: solid 1px g.$c_blue;
      background-color: transparent;
      &:hover{
        background-color: g.$c_white;
      }
    }
    .current{
      display: block;
      width: 48px;
      height: 48px;
      line-height: 45px;
      text-align: center;
      margin: 0 4px;
      background-color: g.$c_blue;
      border: solid 1px g.$c_blue;
      border-radius: 4px;
      color: g.$c_white;
      font-size: 16px;
      font-weight: bold;
    }
  }
}