@use "../global/" as g;

.o-content{
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  @include g.mq_m{
    width: 96%;
    display: flex;
    justify-content: space-between;
  }
  @include g.mq_l{
    width: 100%;
  }
  &__main{
    margin-bottom: 48px;
    @include g.mq_m{
      margin-bottom: 0;
      padding-top: 5px;
      width: calc(100% - 400px);
    }
  }
  &__sidebar{
    padding-bottom: 100px;
    @include g.mq_m{
      width: 340px;
      padding-bottom: 0;
    }
  }
}