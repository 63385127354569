@use "../global/" as g;

.o-blog{
  @include g.mq{
    display: flex;
    flex-wrap: wrap;
  }
  &__detail{
    position: relative;
    margin-bottom: 40px;
    @include g.mq{
      width: 48.3%;
      margin-bottom: 0;
    }
    .image{
      margin-bottom: 16px;
    }
    .text{
      position: relative;
      .top{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 12px;
        margin-bottom: 8px;
        .category{
          display: flex;
          align-items: center;
          gap: 0 8px;
          color: g.$c_blue;
          font-size: 14px;
        }
        .day{
          display: flex;
          align-items: center;
          font-size: 12px;
          color: g.$c_black;
          gap: 0 12px;
          .date{
            color: #9D9D9D;
            font-size: 14px;
            time{
              display: flex;
              align-items: center;
              gap: 0 8px;
            }
          }
        }
      }
      .title{
        font-size: 20px;
        font-weight: bold;
        line-height: 1.4;
        margin-bottom: 16px;
        &.is-mb0{
          margin-bottom: 0;
        }
        a{
          color: g.$c_black;
          padding-bottom: .05em;
          background: linear-gradient(g.$c_black,g.$c_black) 0 100%/0 2px no-repeat;
          transition: background .4s;
          text-decoration: none;
          &:hover{
            background-size: 100% 1px;
          }
        }
      }
      .content{
        font-size: 14px;
        line-height: 1.6;
        color: #B3B3B3;
        margin-bottom: 32px;
        p{
          &:not(:last-child){
            margin-bottom: 16px;
          }
        }
      }
      .btn{
        display: flex;
        justify-content: flex-end;
        a{
          display: flex;
          align-items: center;
          gap: 0 8px;
          font-size: 16px;
          font-weight: 500;
          color: g.$c_blue;
          position: relative;
          &:hover{
            opacity: 1;
            cursor: pointer;
            @include g.mq_m(){
              cursor: pointer;
              &:before{
                content: '';
                position: absolute;
                left: 0;
                bottom: -8px;
                border-bottom: solid 2px g.$c_blue;
                animation: border_anim 0.3s linear forwards;
              }
            }
          }
        }
      }
    }
    &:nth-child(odd){
      @include g.mq{
        margin-right: 3.4%;
      }
    }
    &:not(:nth-last-child(-n+2)){
      @include g.mq{
        margin-bottom: 56px;
      }
    }
  }
}