@use "../global/" as g;

.p-archive{
  padding: 80px 0;
  width: 91.4%;
  max-width: 1200px;
  margin: 0 auto;
  @include g.mq{
    padding: 80px 0 128px;
    width: 95.3%;
  }
  @include g.mq_m{
    padding: 80px 0 256px;
  }
  &__title{
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    gap: 0 8px;
    font-size: 24px;
    color: g.$c_black;
    font-weight: bold;
    padding-bottom: 16px;
    border-bottom: solid 3px g.$c_blue;
    span{
      display: inline-block;
      &:nth-child(1){
        width: 23px;
      }
    }
  }
}