@use "../global/" as g;

@keyframes show {
	0% {
		transform:translate(-100%,0);
		opacity:0;
	}
	100% {
		transform:translate(-50%,0);
    opacity: 1;
	}
}

@keyframes show_text {
	0% {
		transform:translate(-100%,0);
		opacity:0;
	}
	100% {
		transform:translate(0,0);
    opacity: 1;
	}
}

@keyframes show_text_right {
	0% {
		transform:translate(100%,0);
		opacity:0;
	}
	100% {
		transform:translate(0,0);
    opacity: 1;
	}
}

@keyframes load {
	0% {
		top: 0;
	}
	100% {
		top: -100%;
    visibility: hidden;
	}
}

@keyframes border_anim {
	0%{
		width: 0%;
	}
	100%{
		width: 100%;
	}
}

@keyframes ham_open {
	0%{
		top: 100%;
	}
	100%{
		top: 0;
	}
}

@keyframes ham_close {
	0%{
		top: 0;
	}
	100%{
		top: -120%;
	}
}

@keyframes ham_opacity {
	0%{
		opacity: 0;
	}
	75%{
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
}

@keyframes UpAnime{
  from {
		transform: translateY(0);
  }
  to {
		transform: translateY(-100px);
  }
}

@keyframes DownAnime{
  from {
		transform: translateY(-100px);
  }
  to {
		transform: translateY(0);
  }
}

@keyframes DownOpacity{
  from {
		opacity: 0;
  }
  to {
		opacity: 1;
  }
}

@keyframes UpOpacity{
  from {
		opacity: 1;
  }
  to {
		opacity: 0;
  }
}

@keyframes bgroop_sp {
	from {
		background-position: 0 0;
	}
	to {
		background-position: -700px 0;
	}
}

@keyframes bgroop_pc {
	from {
		background-position: 0 0;
	}
	to {
		background-position: -1400px 0;
	}
}

@keyframes bou {
	0% {
			-webkit-clip-path: inset(0 0 100% 0);
			clip-path: inset(0 0 100% 0);
	}
	25% {
			-webkit-clip-path: inset(0 0 0 0);
			clip-path: inset(0 0 0 0);
	}
	75% {
			-webkit-clip-path: inset(0 0 0 0);
			clip-path: inset(0 0 0 0);
	}
	100% {
			-webkit-clip-path: inset(100% 0 0 0);
			clip-path: inset(100% 0 0 0);
	}
}

@keyframes btn_hover {
	from {
		width: 0px;
	}
	to {
		width: calc(100% - 10px);
	}
}

@keyframes zoom_image {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0.7;
		transform:scale(1.05,1.05);
	}
	100% {
		opacity: 1;
		transform:scale(1.0,1.0);
	}
}
@keyframes zoom_mv {
  from {
    transform: scale(1,1);
		opacity: 1;
  }
  to {
    transform: scale(1.01,1.01);
		opacity: 0.4;
  }
}

@keyframes image_opacity_left {
	0% {
		opacity: 0;
		left: -100%;
	}
	100% {
		opacity: 1;
		left: 0;
	}
}

@keyframes image_opacity_right {
	0% {
		opacity: 0;
		left: 100%;
	}
	100% {
		opacity: 1;
		left: 0;
	}
}

@keyframes hover_circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0.5;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes zoomUp {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.15);
  }
}

@keyframes scroll_sp {
	0% {
			transform: translate(0, 0);
	}
	50% {
			transform: translate(0, -48px);
	}
	100% {
			transform: translate(0, 0);
	}
}

@keyframes scroll_pc {
	0% {
			transform: translate(0, 0);
	}
	50% {
			transform: translate(0, -96px);
	}
	100% {
			transform: translate(0, 0);
	}
}

@keyframes UpAnime{
  from {
    opacity: 0;
		transform: translateY(72px);
  }
  to {
    opacity: 1;
	transform: translateY(0);
  }
}

@keyframes DownAnime{
  from {
  	opacity: 1;
		transform: translateY(0);
  }
  to {
  	opacity: 1;
		transform: translateY(72px);
  }
}

@keyframes loop {
	0% {
			transform: translateX(100%);
	}
	to {
			transform: translateX(-100%);
	}
}

@keyframes loop2 {
	0% {
			transform: translateX(0);
	}
	to {
			transform: translateX(-200%);
	}
}

@keyframes mv_scroll {
	0% {
		top: -4px;
	}
	100% {
		top: 96px;
	}
}