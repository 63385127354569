@use "../global/" as g;

.o-cv{
  background-color: g.$c_white;
  padding: 48px 16px 40px;
  &__title{
    text-align: center;
    font-size: 34px;
    line-height: 1.4;
    color: #333;
    font-weight: bold;
    margin-bottom: 24px;
    span{
      display: inline-block;
    }
  }
  &__list{
    background-color: #F9F9F9;
    padding: 14px 16px;
    margin-bottom: 24px;
    width: 100%;
    margin: 0 auto 24px;
    max-width: 585px;
    @include g.mq{
      padding: 24px 48px;
    }
    li{
      padding-left: 24px;
      position: relative;
      font-size: 14px;
      line-height: 1.8;
      &:before{
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        background-image: url(../images/common/sidebar_check.svg);
        background-size: 100% 100%;
        top: 5px;
        left: 0;
      }
      &:not(:last-child){
        margin-bottom: 16px;
      }
    }
  }
  &__text{
    color: #333;
    font-size: 14px;
    line-height: 1.8;
    margin-bottom: 24px;
    @include g.mq{
      text-align: center;
      font-size: 16px;
      margin-bottom: 32px;
    }
    .is-tab{
      display: none;
      @include g.mq{
        display: block;
      }
    }
  }
  &__btn{
    background-color: g.$c_yellow;
    padding: 16px 10px;
    border-radius: 4px;
    width: 100%;
    max-width: 552px;
    margin: 0 auto;
    @include g.mq{
      background-color: transparent;
      padding: 0;
    }
    &__flex{
      margin-bottom: 16px;
      @include g.mq{
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
      }
      .detail{
        width: 100%;
        max-width: 264px;
        margin: 0 auto;
        @include g.mq{
          margin: 0;
        }
        p{
          text-align: center;
          margin-bottom: 10px;
          font-size: 14px;
          font-weight: bold;
          span{
            position: relative;
            &:before{
              content: "";
              position: absolute;
              width: 1px;
              height: 16px;
              background-color: #333;
              top: 0;
              bottom: 0;
              margin: auto;
              right: -16px;
              transform: rotate(30deg);
            }
            &:after{
              content: "";
              position: absolute;
              width: 1px;
              height: 16px;
              background-color: #333;
              top: 0;
              bottom: 0;
              margin: auto;
              left: -16px;
              transform: rotate(-30deg);
            }
          }
        }
        a{
          position: relative;
          display: flex;
          border-radius: 60px;
          height: 56px;
          align-items: center;
          justify-content: center;
          width: 100%;
          font-size: 14px;
          color: g.$c_white;
          font-weight: bold;
          transition: 0.3s;
          span:nth-child(1){
            left: 24px;
            top: 0;
            bottom: 0;
            margin: auto;
            position: absolute;
            align-items: center;
            display: flex;
          }
          span:nth-child(3){
            right: 24px;
            top: 0;
            bottom: 0;
            margin: auto;
            position: absolute;
            align-items: center;
            display: flex;
          }
        }
        &:nth-child(1){
          margin: 0 auto 16px;
          @include g.mq{
            margin: 0;
          }
          a{
            background-color: g.$c_blue;
            border: solid 1px g.$c_blue;
            &:hover{
              color: g.$c_blue;
              opacity: 1;
              background-color: g.$c_white;
              svg{
                path{
                  fill: g.$c_blue;
                }
              }
            }
          }
        }
        &:nth-child(2){
          p{
            display: none;
            @include g.mq{
              display: block;
            }
          }
          a{
            background-color: g.$c_blue;
            border: solid 1px g.$c_blue;
            &:hover{
              color: g.$c_blue;
              opacity: 1;
              background-color: g.$c_white;
              svg{
                path{
                  fill: g.$c_blue;
                }
              }
            }
          }
        }
      }
    }
    &__tel{
      width: 100%;
      max-width: 252px;
      margin: 0 auto;
    }
  }
}