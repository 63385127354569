@use "../global/" as g;

.o-title {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  &.is-center{
    justify-content: center;
  }
  span:nth-child(1){
    display: inline-block;
    width: 19px;
    margin-right: 8px;
  }
  span:nth-child(2){
    font-size: 26px;
  }
}