@use "../global/" as g;

.o-cta{
  background-color: g.$c_blue;
  padding: 32px 16px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  @include g.mq{
    padding: 48px 32px 24px;
  }
  &.is-single_cta{
    margin-bottom: 32px;
    padding: 16px;
    @include g.mq{
      padding: 16px;
    }
    p{
      margin-bottom: 0 !important;
      line-height: 1.4 !important;
    }
  }
  &__title{
    text-align: center;
    color: g.$c_white;
    margin-bottom: 48px;
    @include g.mq{
      text-align: left;
      padding-left: 285px;
    }
    .is-single & {
      @include g.mq_m{
        padding-left: 168px;
        margin-bottom: 32px;
      }
    }
    p{
      &:nth-child(1){
        font-size: 28px;
        font-weight: bold;
        @include g.mq{
          font-size: 36px;
        }
        @include g.mq_m{
          font-size: 48px;
        }
        .is-single & {
          @include g.mq_m{
            font-size: 24px;
          }
          @include g.mq_mm{
            font-size: 32px;
          }
          @include g.mq_l{
            font-size: 40px;
          }
        }
      }
      &:nth-child(2){
        display: none;
        margin-top: 12px;
        font-size: 16px;
        line-height: 1.4;
        @include g.mq{
          display: block;
        }
      }
    }
  }
  &__sp_text{
    padding-left: 96px;
    font-size: 12px;
    line-height: 1.6;
    letter-spacing: 0.6px;
    margin-bottom: 24px;
    color: g.$c_white;
    font-weight: 400;
    @include g.mq{
      display: none;
    }
  }
  &__link{
    position: relative;
    @include g.mq_m{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 24px 0;
    }
    &__illust{
      position: absolute;
      width: 99px;
      left: -11px;
      top: -97px;
      @include g.mq{
        width: 295px;
        top: -246px;
        left: -32px;
      }
      .is-single & {
        @include g.mq_m{
          width: 185px;
          top: -174px;
          left: -25px;
        }
      }
    }
    &__detail{
      position: relative;
      background-color: g.$c_white;
      padding: 16px 0;
      @include g.mq_m{
        padding: 32px 0;
        width: 49.3%;
      }
      .is-single & {
        @include g.mq_m{
          padding: 24px 0;
        }
      }
      .is-single_cta & {
        @include g.mq_m{
          padding: 16px 0;
        }
      }
      &:not(:last-child){
        margin-bottom: 12px;
        @include g.mq_m{
          margin-bottom: 0;
        }
      }
      &-title{
        color: g.$c_black;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 16px;
        line-height: 1.4;
      }
      &-tel{
        p{
          &:nth-child(1){
            margin-bottom: 6px;
            a{
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 0 6px;
              color: g.$c_black;
              font-size: 28px;
              font-weight: bold;
              @include g.mq{
                font-size: 48px;
              }
              .is-single & {
                @include g.mq_m{
                  font-size: 32px;
                }
              }
              span{
                &:nth-child(1){
                  width: 28px;
                  display: inline-block;
                  @include g.mq{
                    width: 52px;
                  }
                  .is-single & {
                    @include g.mq_m{
                      width: 34px;
                    }
                  }
                }
              }
            }
          }
          &:nth-child(2){
            font-size: 12px;
            text-align: center;
            font-weight: 300;
            @include g.mq{
              font-size: 18px;
            }
            .is-single & {
              @include g.mq_m{
                font-size: 14px;
              }
            }
          }
        }
      }
      &-contact{
        width: 91.4%;
        height: 54px;
        max-width: 440px;
        background-color: g.$c_orange;
        border-radius: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: g.$c_white;
        font-weight: bold;
        position: relative;
        border: solid 1px g.$c_orange;
        transition: 0.3s;
        margin: 0 auto;
        @include g.mq{
          height: 80px;
          font-size: 24px;
        }
        .is-single & {
          @include g.mq_m{
            height: 64px;
            font-size: 18px;
          }
        }
        &:hover{
          background-color: g.$c_white;
          color: g.$c_orange;
          opacity: 1;
          &:before{
            border-top: solid 2px g.$c_orange;
            border-right: solid 2px g.$c_orange;
          }
        }
        &:before{
          content: "";
          position: absolute;
          content: '';
          width: 10px;
          height: 10px;
          border-top: solid 2px g.$c_white;
          border-right: solid 2px g.$c_white;
          position: absolute;
          right: 24px;
          top: 0px;
          bottom: 0;
          margin: auto;
          transition: 0.3s;
          transform: rotate(45deg);
        }
      }
    }
  }
}