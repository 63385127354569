@use "../global/" as g;


.p-contact{
  &__inner{
    width: 100%;
    max-width: 1062px;
    margin: 0 auto;
  }
  &__text{
    font-size: 16px;
    margin-bottom: 24px;
    line-height: 1.8;
    color: g.$c_black;
  }
}