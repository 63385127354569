@use "../global/" as g;

.o-recommend{
  background-color: #F9F9F9;
  padding: 35px 0;
  @include g.mq{
    padding: 70px 0;
  }
  &__content{
    @include g.mq{
      display: flex;
      justify-content: space-between;
    }
    &__detail{
      width: 100%;
      &:not(:last-child){
        margin-bottom: 60px;
        @include g.mq{
          margin-bottom: 0;
        }
      }
      @include g.mq{
        width: 30.8%;
      }
      @include g.mq_m{
        width: 30.8%;
      }
      &-image{
        margin-bottom: 24px;
      }
      &-text{
        padding-left: 12px;
        position: relative;
        &:before{
          content: "";
          position: absolute;
          width: 1px;
          height: 100%;
          left: 0;
          background-color: g.$c_black;
        }
        @include g.mq{
          padding-left: 24px;
        }
        .is-flex{
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: 24px;
          .date{
            @include g.mq{
              margin-top: 20px;
            }
            @include g.mq_m{
              margin-top: 0;
            }
            time{
              display: flex;
              align-items: center;
              line-height: 1;
              img{
                display: inline-block;
                margin-right: 6px;
              }
            }
          }
        }
        .category{
          span{
            background-color: #FFFDDA;
            font-size: 10px;
            color: #999999;
            font-weight: 500;
            line-height: 1;
            padding: 4px 16px;
            @include g.mq{
              font-size: 16px;
            }
          }
        }
        .title{
          font-size: 15px;
          line-height: 22px;
          @include g.mq{
            font-size: 18px;
            line-height: 30px;
          }
          @include g.mq_m{
            font-size: 22px;
            line-height: 33px;
          }
          a{
            color: #333;
          }
        }
      }
    }
  }
}