@use "../global/" as g;

.p-authorized{
  padding: 80px 0;
  width: 91.4%;
  max-width: 1200px;
  margin: 0 auto;
  @include g.mq{
    padding: 80px 0 128px;
  }
  @include g.mq_m{
    padding: 80px 0 256px;
  }
  p{
    font-size: 16px;
    color: g.$c_black;
    line-height: 1.8;
    @include g.mq{
      font-size: 18px;
    }
    &:not(:last-child){
      margin-bottom: 24px;
      @include g.mq{
        margin-bottom: 32px;
      }
    }
    &.is-center{
      text-align: center;
    }
    &.is-right{
      text-align: right;
    }
  }
}